<template>
  <v-container grid-list-xl fluid>
    <v-layout row wrap>
      <v-flex sm12>
        <div
          class='display-1 green--text text--darken-4 font-weight-medium center-text'
        >
          Do you have allergy problems?
        </div>
        <br />
        <div class='title green--text text--darken-4 center-text'>
          Answer the following questions. These questions will help us to
          determine the allergies you are facing.
        </div>
        <br />
        <div class='title green--text text--darken-4 center-text'>
          Also tell us how are you feeling based on following scale
        </div>
        <br />
        <div>
          <img
            src='../../assets/pain-scale-500px.jpg'
            style='margin-left: auto;margin-right: auto;display: block'
          />
        </div>
      </v-flex>
      <v-flex sm12>
        <FormPatientComp></FormPatientComp>
      </v-flex>
      <v-flex sm12>
        <AllergySurveyComp ref='allergyComp'></AllergySurveyComp>
        <!--<AllergyQuestsComp></AllergyQuestsComp>-->
      </v-flex>
      <v-flex sm12>
        <v-btn color='primary' @click='saveData'>
          <v-icon class='iconText'>fa fa-save</v-icon>
          Save
        </v-btn>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import WebH from '../../api/web';
import FormPatientComp from '../../components/patients/FormPatient';
import AllergySurveyComp from '../../components/forms/AllergySurveyV1';
// import AllergyQuestsComp from '../../components/forms/AllergyQuests';
export default {
  components: {
    FormPatientComp,
    AllergySurveyComp
    //AllergyQuestsComp
  },
  computed: {
    patient: {
      get() {
        return this.$store.getters['FormsStore/patient'];
      }
    }
  },
  methods: {
    async notify(message) {
      await this.$notifyDlg.show(message, 'Error', 'error');
    },
    async checkForPatientDetails() {
      return this.patient.Pid !== '' && this.patient.Mobile !== '';
    },
    async saveData() {
      const patientCheck = await this.checkForPatientDetails();
      if (!patientCheck) {
        await this.notify('Fill correct patient details');
        return;
      }
      const vModel = {
        Problems: this.$refs.allergyComp.getData(),
        PatientId: this.patient.Pid,
        Mobile: this.patient.Mobile
      };
      // const vModel = {
      //   PatientId: this.patient.Pid,
      //   Mobile: this.patient.Mobile,
      //   Problems: JSON.stringify(this.$store.getters['FormsStore/Problems']),
      //   Treatments: JSON.stringify(
      //     this.$store.getters['FormsStore/Treatments']
      //   ),
      //   Family: JSON.stringify(this.$store.getters['FormsStore/Family']),
      //   Habits: JSON.stringify(this.$store.getters['FormsStore/Habits']),
      //   Others: JSON.stringify(this.$store.getters['FormsStore/Others']),
      //   Children: JSON.stringify(this.$store.getters['FormsStore/Children'])
      // };
      this.submit(vModel);
    },
    async submit(data) {
      const loader = this.$loading.show();
      const res = await WebH.Post('/allergies', data);
      loader.hide();
      if (res.Success) {
        await this.$notifyDlg.show(
          'Answers submitted. Thank you',
          'Info',
          'info'
        );
        this.$store.commit('FormsStore/reset');
        this.$refs.allergyComp.clear();
      } else {
        await this.notify(res.Data);
      }
    }
  }
};
</script>

<style scoped></style>
