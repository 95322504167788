<template>
  <v-container grild-list-xl fluid>
    <v-layout row wrap>
      <v-flex sm12>
        <div class='headline font-weight-medium'>
          My complaints
        </div>
        <br />
        <v-divider></v-divider>
        <br />
      </v-flex>

      <v-flex xs12 sm12 lg6 v-for='(problem, index) in problems' :key='index'>
        <div>
          <div class='subheading font-weight-medium problemHeader'>
            {{ problem.Name }}
          </div>
          <div style='display: none'>
            {{ (model[index].Name = problem.Name) }}
          </div>
          <v-list>
            <v-list-item v-for='option in problem.Options' :key='option'>
              <v-checkbox
                v-model='model[index].Choices'
                :label='option'
                :value='option'
                color='primary'
              ></v-checkbox>
            </v-list-item>
          </v-list>
          <div class='problemHeader' v-if='problem.Name !== "Others"'>
            <v-text-field
              label='Others:'
              v-model='model[index].Others'
            ></v-text-field>
          </div>
          <div class='problemHeader'>
            <v-text-field
              label='Since:'
              v-model='model[index].Since'
            ></v-text-field>
          </div>
          <div class='problemHeader'>
            <v-slider
              v-model='model[index].Scale'
              :tick-labels='tickLabels'
              :max='10'
              step='1'
              ticks='always'
              thumb-label='always'
              :thumb-size='24'
              label='Severity:'
            >
            </v-slider>
          </div>
        </div>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  props: {
    problemData: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      model: [
        { Name: '', Choices: [], Others: '', Since: '', Scale: 0 },
        { Name: '', Choices: [], Others: '', Since: '', Scale: 0 },
        { Name: '', Choices: [], Others: '', Since: '', Scale: 0 },
        { Name: '', Choices: [], Others: '', Since: '', Scale: 0 },
        { Name: '', Choices: [], Others: '', Since: '', Scale: 0 },
        { Name: '', Choices: [], Others: '', Since: '', Scale: 0 },
        { Name: '', Choices: [], Others: '', Since: '', Scale: 0 },
        { Name: '', Choices: [], Others: '', Since: '', Scale: 0 },
        { Name: '', Choices: [], Others: '', Since: '', Scale: 0 }
      ],
      tickLabels: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      problems: [
        {
          Name: 'Eye',
          Options: [
            'Redness',
            'Watery/Tears',
            'Abnormal sensation',
            'Itchy',
            'Eyelid swelling'
          ]
        },
        {
          Name: 'Nose',
          Options: [
            'Sneezing',
            'Running nose',
            'Block',
            'Itching',
            'Discharge (Cold)'
          ]
        },
        {
          Name: 'Ears',
          Options: ['Discharge', 'Block', 'Ache', 'Hearing loss', 'Ringing']
        },
        {
          Name: 'Mouth',
          Options: [
            'Paining on swallowing',
            'Hoarseness',
            'Ulcer',
            'Irritation',
            'Lips swelling',
            'Tongue swelling',
            'Itchy'
          ]
        },
        {
          Name: 'Chest',
          Options: [
            'Cough',
            'Sputum',
            'Breathlessness/Tightness',
            'Wheeze',
            'Chest pain',
            'Blood in sputum'
          ]
        },
        {
          Name: 'Skin',
          Options: [
            'Irritation',
            'Rash',
            'Redness',
            'Wheals',
            'Itching',
            'Dryness',
            'Scales',
            'Rough',
            'Burning',
            'Hives'
          ]
        },
        {
          Name: 'Sleep',
          Options: ['Reduced', 'Daytime', 'Snoring', 'Disturbed']
        },
        {
          Name: 'Stomach',
          Options: [
            'Diarrhoea',
            'Nausea/Vomiting',
            'Constipation',
            'Heart burn',
            'Belching'
          ]
        },
        {
          Name: 'Others',
          Options: [
            'Headache',
            'Fever',
            'Body ache',
            'Weight loss',
            'Loss of appetite'
          ]
        }
      ]
    };
  },
  methods: {
    clear() {
      this.model = [
        { Name: '', Choices: [], Others: '', Since: '', Scale: 0 },
        { Name: '', Choices: [], Others: '', Since: '', Scale: 0 },
        { Name: '', Choices: [], Others: '', Since: '', Scale: 0 },
        { Name: '', Choices: [], Others: '', Since: '', Scale: 0 },
        { Name: '', Choices: [], Others: '', Since: '', Scale: 0 },
        { Name: '', Choices: [], Others: '', Since: '', Scale: 0 },
        { Name: '', Choices: [], Others: '', Since: '', Scale: 0 },
        { Name: '', Choices: [], Others: '', Since: '', Scale: 0 },
        { Name: '', Choices: [], Others: '', Since: '', Scale: 0 }
      ];
    },
    getData() {
      return this.model;
    }
  },
  watch: {
    problemData: function() {
      if (!this.problemData.Problems) return;
      if (
        this.problemData.Problems === null ||
        this.problemData.Problems.Object === null
      )
        return;
      const pr = this.problemData.Problems.Object;
      for (let i = 0; i < pr.length; i++) {
        const index = this.problems.findIndex(x => x.Name === pr[i].Name);
        if (index === -1) continue;
        this.model[index].Name = pr[i].Name;
        this.model[index].Choices = pr[i].Choices;
        this.model[index].Others = pr[i].Others;
        this.model[index].Since = pr[i].Since;
        this.model[index].Scale = pr[i].Scale;
      }
    }
  }
};
</script>

<style scoped>
.problemHeader {
  margin-left: 15px;
}
</style>
